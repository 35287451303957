import service from "../service.js";

export const search = params => {
    return service({
        method: 'POST',
        url: '/deviceCrk/search',
        params
    })
}

export const queryCrkInfo = (id, type) => {
    return service({
        method: 'GET',
        url: '/deviceCrk/queryCrkInfo',
        params: {
            id,
            type
        }
    })
}


export const getCkInfo = (oddNum, deviceCode) => {
    return service({
        method: 'GET',
        url: '/deviceCrk/getCkInfo',
        params: {
            oddNum,
            deviceCode
        }
    })
}

export const queryDeviceInfo = (crkType, deviceCode) => {
    return service({
        method: 'GET',
        url: '/deviceCrk/queryDeviceInfo',
        params: {
            crkType,
            deviceCode
        }
    })
}

export const ckInventory = (data) => {
    return service({
        method: 'POST',
        url: '/deviceCrk/ckInventory',
        data
    })
}

export const rkInventory = (data) => {
    return service({
        method: 'POST',
        url: '/deviceCrk/rkInventory',
        data
    })
}

export const rkDetail = params => {
    return service({
        method: 'POST',
        url: '/deviceCrk/rkDetail',
        params
    })
}

// export const downloadExcel = params => {
//     return axios({
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/vnd.ms-excel;charset=utf-8',
//             token: getToken('token')
//         },
//         responseType: 'blob',
//         url: process.env.VUE_APP_URL + '/deviceCrk/downloadExcel',
//         params
//     })
// }

export const crkTypeList = [{ code: '00', name: '正常使用' }, { code: '01', name: '外送维修' }, { code: '02', name: '外送校验' }, { code: '03', name: '借出使用' }, { code: '04', name: '转移库房' }]
export const rkTypeList = [{ code: '00', name: '使用返回' }, { code: '01', name: '维修返回' }, { code: '02', name: '校验返回' }, { code: '03', name: '借出归还' }, { code: '04', name: '仪器暂存' }]

export const deviceCKRules = {
    testDate: [{ required: true, message: '请选择试验日期' }],
    testType: [{ required: true, message: '请填写试验类别' }],
    station: [{ required: true, message: '请填写站所' }],
    intervalDesc: [{ required: true, message: '请填写间隔' }],
    testNature: [{ required: true, message: '请填写试验性质' }],
    useDeadline: [{ required: true, message: '请选择使用期期限' }],
    testFzr: [{ required: true, message: '请填写试验负责人' }],
    tester: [{ required: true, message: '请填写试验人员' }],
    wsPersonnel: [{ required: true, message: '请填写外送人员' }],
    wsWay: [{ required: true, message: '请填写外送方式' }],
    checkWaring: [{ required: true }],
    reason: [{ required: true, message: '请填写原因' }],
    isXdfj: [{ required: true }],
    wsDate: [{ required: true, message: '请选择外送日期' }],
    jsUnit: [{ required: true, message: '请填写接受单位' }],
    jsPersonnel: [{ required: true, message: '请填写接受人员' }],
    contactWay: [{ required: true, message: '请填写联系方式' }],
    jcDate: [{ required: true, message: '请选择借出日期' }],
    jcDeadline: [{ required: true, message: '请选择借出期限' }],
    jcPersonnel: [{ required: true, message: '请填写借出人员' }],
    approvePersonnel: [{ required: true, message: '请填写审批人员' }],
    zyDate: [{ required: true, message: '请选择转移日期' }],
    zyPersonnel: [{ required: true, message: '请填写转移人员' }],
    mbkf: [{ required: true, message: '请选择目标库房' }],
    mbLocation: [{ required: true, message: '请填写目标位置' }],
    zyCause: [{ required: true, message: '请填写转移原因' }],
    ckJbr: [{ required: true, message: '请填写出库经办人' }],
    ckDate: [{ required: true, message: '请选择出库日期' }],
    ckTime: [{ required: true, message: '请选择出库时间' }]
}


export const deviceRKRules = {
    isWgjcOk: [{ required: true, message: '请选择试验日期' }],
    wgjcImage: [{ required: true, message: '请填写试验类别' }],
    xnCheck: [{ required: true, message: '请填写站所' }],
    isCheckoutOk: [{ required: true, message: '请填写间隔' }],
    checkDate: [{ required: true, message: '请填写试验性质' }],
    xyTag: [{ required: true, message: '请选择使用期期限' }],
    xyCer: [{ required: true, message: '请填写试验负责人' }],
    rkJbr: [{ required: true, message: '请填写试验人员' }],
    rkDate: [{ required: true, message: '请选择出库日期' }],
    rkTime: [{ required: true, message: '请选择出库时间' }]
}

